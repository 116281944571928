import Config from '../Config'

const Document = {
  sign,
  getByToken,
}
export default Document

function sign(token) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({token})
  }

  return fetch(`${Config.API_URL}${process.env.REACT_APP_SIGNATURE_SIGN}`, requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      return json
    })
}

function getByToken(token) {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return fetch(`${Config.API_URL}${process.env.REACT_APP_SIGNATURE_TOKEN}${token}`, requestOptions)
    .then(function(response){
      return response.json()
    })
    .then(function(json){
      return json
    })
}
