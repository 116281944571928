import React from 'react'
import { Route } from "react-router-dom"
import BlankPage from '../components/layouts/BlankPage'

export const PublicBlankPage = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={
      matchProps => (
        <BlankPage>
          <Component {...matchProps} />
        </BlankPage>
      )
    } />
  )
}
