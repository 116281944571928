import React from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

const styles = {
    confirmButtonText: 'Aceptar',
    confirmButtonColor: '#1D2B56',

    cancelButtonText: 'Cancelar',
    cancelButtonColor: '#1D2B56',
}

export const ShowAlert = {
    success,
    error,
    close,
}

function close() {
    return MySwal.close()
}

function success(message) {
    return MySwal.fire({
        ...styles,
        title: <p>Listo!</p>,
        html: message,
        icon: 'success',
    })
}

function error(message) {
    return MySwal.fire({
        ...styles,
        title: <p>Ops...</p>,
        html: message,
        icon: 'error',
    })
}
