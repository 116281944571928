import React from 'react'

const BlankPage = ({ children, ...rest }) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <main role="main" className="col-12 col-md-8 offset-md-2 p-4 p-md-5 mt-md-5 mb-md-5 bg-white">
          {children}
        </main>
      </div>
    </div>
  )
}

export default BlankPage
