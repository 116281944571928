import React, { Component } from 'react'
import AppRoute from './routes/AppRoute'
import './css/Bootstrap.css'
import './css/App.css'

class App extends Component {
  render() {
    return (
        <AppRoute />
    )
  }
}
export default App
