import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Moment from 'react-moment'
import moment from 'moment'
import { ShowAlert } from '../commons/ShowAlert'
import Document from '../../api/Document'
import Logo from '../../media/images/GlobeMind.png'

const SignStatement = (props) => {

  const history = useHistory()
  const [token, setToken] = useState({})
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState('')
  const [SS, setSS] = useState({})
  const [signed, setSigned] = useState(null)

  const handleSubmit = (e) => {
      setLoading(true)
      setErrors('')
      e.preventDefault()
      Document.sign(token)
      .then(data => {
        console.log(data)
        if (data.errors) {
          setErrors(data.errors[0].detail)
        } else {
          ShowAlert.success('Ha firmado el Documento').then(() => {
            setSigned(moment())
          })
        }
        setLoading(false)
      })
  }

  const getByToken = (token) => {
    setLoading(true)
    setErrors('')
    Document.getByToken(token).then(data => {
      if (data.errors || data.status == 'error') {
        // setErrors(data.errors[0].detail)
        setErrors('Ha ocurrido un error')
      } else {
        if ( data.data && data.data.signed_at ) {
          setSigned(data.data.signed_at)
        }
        setToken(token)
        setSS(data.data)
      }
      setLoading(false)
    })
  }

  const queryToken= useCallback(()=>{
    const query = new URLSearchParams(props.location.search);
    if ( query.get('token') ) {
      getByToken(query.get('token'))
    } else {
      history.push("/")
    }
  },[props.location.search,history]);



  useEffect(()=>{
    queryToken();
  },[queryToken]);

  return (
    <>
    {
      errors.length > 0 && <div className="alert alert-danger" role="alert">{errors}</div>
    }
    {
      loading &&
      <div className="row text-center">
        <div className="col-12">
          <img src={Logo} width="300" className="pulse-effect" alt="GlobeMind" />
        </div>
      </div>
    }
    {
      !loading && errors.length === 0 &&
      <div className="row sign-document">
        <div className="col-12 col-md-10 offset-md-1 text-center">

          <div className="row mb-5">
            <div className="col-12">
              <img src={Logo}  width="300" alt="Logo"/>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-12">
              <h5 className="title">
                {
                  SS.sworn_statement && SS.sworn_statement.name
                }
              </h5>
            </div>
          </div>

          <div className="row mb-5">
            <div
              className="col-12 text-left"
              dangerouslySetInnerHTML={{__html: SS.sworn_statement && SS.sworn_statement.statement }} />
          </div>

          <div className="row">
            <div className="col-12">
              {
                signed
                ?
                <strong>Documento firmado: <Moment format="DD/MM/YYYY HH:mm">{signed}</Moment>hs</strong>
                :
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={loading}
                  >
                  {loading ? 'Cargando...' : 'Firmar Documento'}
                </button>
              }
            </div>
          </div>

        </div>
      </div>
    }
    </>
  )
}
export default SignStatement
