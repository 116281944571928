import React from "react"
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { PublicBlankPage } from './RouteLayout'
import SignDocument from "../components/ddjjs/SignDocument"

const NotFound = () => {
  window.location.href = 'https://globemind.com/'
  return false
}

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Switch data="data">

        <PublicBlankPage
          path="/signdocument"
          component={ SignDocument }
          render={ props => <SignDocument {...props} /> }
          />

        <Route component={NotFound} />

      </Switch>
    </BrowserRouter>
  )
}
export default AppRoute
